<script setup>
/* istanbul ignore file */
import { ref, onMounted, onUnmounted, nextTick } from "vue";

const loaderRef = ref(null);

onMounted(async () => {
    await nextTick();
    window.oneX.addElement(loaderRef.value);
});

onUnmounted(() => {
    window.oneX.removeElement(loaderRef.value);
});
</script>

<template>
  <div
    id="loader"
    ref="loaderRef"
  >
    <div
      class="-oneX-page-loader"
      style="display: inline"
    >
      <div
        class="-oneX-loader-icon"
        aria-hidden="true"
      />
      <div
        class="-oneX-loaderdiv"
        aria-hidden="true"
      >
        <svg class="-oneX-small">
          <circle
            cx="32"
            cy="32"
            r="28.8"
            viewBox="0 0 64 64"
          />
        </svg>

        <svg class="-oneX-medium">
          <circle
            cx="40"
            cy="40"
            r="36"
            viewBox="0 0 80 80"
          />
        </svg>

        <svg class="-oneX-large">
          <circle
            cx="50"
            cy="50"
            r="45"
            viewBox="0 0 100 100"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<style>
.-oneX-loaderdiv {
    margin-top: 15%;
    margin-bottom: 20%;
}
</style>
