import { ref, watch } from "vue";
import { exchangeOauth2Tokens } from "customer-oauth2-token-management-utility";

const defaultAllowedSessionTime = 800; // Change back to 800 after Testing
const timeAtWhichModalDisplays = 90; // Change back to 90 after Testing

const showSessionLogoutModal = ref(false);
const remainingSessionTime = ref(defaultAllowedSessionTime);
const isLogoutTriggered = ref(false);
const isRestartSessionTimer = ref(false);

let timer = null;
let logoutUrl = "";
let backupUrl = "https://www.statefarm.com";

watch(
    () => remainingSessionTime.value,
    currentRemainingSessionTime => {
        if (currentRemainingSessionTime <= timeAtWhichModalDisplays) {
            showSessionLogoutModal.value = true;
        }

        if (currentRemainingSessionTime <= 0) {
            clearInterval(timer);
            isLogoutTriggered.value = true;
            window.location.href = logoutUrl ?? backupUrl;
        }
    }
);

const startSessionTimer = LOGOUT_URL => {
    logoutUrl = LOGOUT_URL;
    remainingSessionTime.value = defaultAllowedSessionTime;

    const interval = setInterval(() => {
        remainingSessionTime.value -= 1;
    }, 1000);

    timer = interval;
};

export const restartSessionTimer = async LOGOUT_URL => {
    showSessionLogoutModal.value = false;
    remainingSessionTime.value = defaultAllowedSessionTime;
    clearInterval(timer);
    startSessionTimer(LOGOUT_URL);
};

export const refreshTokens = async LOGOUT_URL => {
    if (!import.meta.env.VITE_MOCK_USER) {
        try {
            const successfulRefresh = await exchangeOauth2Tokens();
            if (!successfulRefresh) {
                window.location.href = logoutUrl ?? "";
            }
            restartSessionTimer(LOGOUT_URL);
        } catch (e) {
            console.error(e);
            window.location.href = logoutUrl ?? "";
        }
    } else {
        restartSessionTimer(LOGOUT_URL);
    }
};

const startUserSession = ({ LOGOUT_URL }) => {
    startSessionTimer(LOGOUT_URL);
};

const removeSessionEventListeners = () => {
    clearInterval(timer);
    showSessionLogoutModal.value = false;
    isLogoutTriggered.value = false;
};

const useSession = () => ({
    isLogoutTriggered,
    remainingSessionTime,
    showSessionLogoutModal,
    startUserSession,
    removeSessionEventListeners,
    refreshTokens,
    restartSessionTimer
});

export default useSession;
