<script setup>
import { onErrorCaptured, ref, inject } from "vue";
import { API, API_URL } from "../util/constants/provideKeys";
import useLogger from "../hooks/logger/useLogger";

const api = inject(API);
const apiUrl = inject(API_URL);

const { log } = useLogger(api, apiUrl);

const hasError = ref(false);

onErrorCaptured(error => {
    console.error(error);
    hasError.value = true;

    log("ERROR", error.toString());
    // prevents errors from propagating up the error boundary chain
    return false;
});
</script>

<template>
  <span
    v-if="!hasError"
    class="error-boundary"
  >
    <slot />
  </span>
</template>
