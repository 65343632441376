<script setup>
import { ref, onMounted, nextTick, inject } from "vue";
import { MY_ACCOUNTS_URL } from "../util/constants/provideKeys";
import useContentBundle from "../hooks/contentBundle/useContentBundle";

const myAccountsUrl = inject(MY_ACCOUNTS_URL);

const { contentBundleStrings } = useContentBundle();

const breadcrumbRef = ref(null);

onMounted(async () => {
    await nextTick();
    window.oneX.addElement(breadcrumbRef.value);
});
</script>

<template>
  <section
    :ref="breadcrumbRef"
    class="-oneX-breadcrumbs -oneX-container-fluid"
  >
    <div>
      <nav aria-label="breadcrumb">
        <ul
          itemscope
          itemtype="https://schema.org/BreadcrumbList"
        >
          <li
            itemprop="itemListElement"
            itemtype="https://schema.org/ListItem"
          >
            <a :href="myAccountsUrl">
              <span
                class="-oneX-breadcrumbs-link-name"
                itemprop="name"
              >
                {{ contentBundleStrings?.myAccounts }}
              </span>
              <span class="-oneX-breadcrumbs-chevron" />
              <meta itemprop="position">
            </a>
          </li>
          <li
            itemprop="itemListElement"
            itemtype="https://schema.org/ListItem"
          >
            <a>
              <span
                class="-oneX-breadcrumbs-link-name"
                itemprop="name"
              >
                {{ contentBundleStrings?.breadcrumbLabelMigrationPortal }}
              </span>
              <meta itemprop="position">
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </section>
</template>
