export const API = 'API';
export const API_URL = 'API_URL';
export const API_TIMEOUT_IN_MS = 'API_TIMEOUT_IN_MS';
export const MY_ACCOUNTS_URL = 'MY_ACCOUNTS_URL';
export const LOGIN_URL = 'LOGIN_URL';
export const LOGOUT_URL = 'LOGOUT_URL';
export const CONTENT_BUNDLE_FILE = '/content-bundle.json';
export const DPP_URL = 'DPP_URL';
export const DOCUMENT_CENTER_AUTO_URL = 'DOCUMENT_CENTER_AUTO_URL';
export const MAKE_PAYMENT_URL = 'MAKE_PAYMENT_URL';
export const PAYMENT_SCHEDULE_URL = 'PAYMENT_SCHEDULE_URL';
export const IS_PRODUCTION_ENV = 'IS_PRODUCTION_ENV';
