import getCookie from '@Config/getCookie';

const useLogger = (api, apiUrl) => {
    const log = async (level, message, user = getCookie('SFcustId') || 'unknownxxxx') => {
        try {
            await api.post(`${apiUrl}/log`, { level, message, user });
        } catch (err) {
            console.error(err);
        }
    };

    return {
        log
    };
};

export default useLogger;
