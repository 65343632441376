import constants from './constants/constants';
import redirectToPage from '@Routing/redirectToPage';
import setupConfig from '@Config/setupConfig';
import getCookie from '@Config/getCookie';
import { verifyJwtSignature } from './verifyJwtSignature';

export const getExpClaimFromJwt = async jwt => {
    try {
        const result = await verifyJwtSignature(jwt);
        const { exp } = result.payload;
        return exp;
    } catch (error) {
        throw new Error(`Error decoding JWT with Jose ${error}`);
    }
};

/**
 * @param testDecodedTokenInput This takes an object that is the expiration claim (JWT expiration).
 * This is used as a trapdoor for testing because fully-mocking this was silly.
 * If not given, it takes its default value of '' and then the full call to jose.decodeJwt()
 * is made to pull out the expiration value.
 * @return boolean.
 */
const jwtIsExpired = async (testDecodedTokenExp = '') => {
    if (import.meta.env.VITE_MOCK_USER) {
        return false;
    }

    const { LOGIN_URL } = setupConfig();
    const EXPIRATION_OFFSET = constants.times.TWO_MINUTE_FIFTY_FIVE_IN_SECONDS;

    const accessToken = getCookie(constants.cookies.OKTA_ACCESS_TOKEN);

    try {
        const getDecodedTokenExp = () => {
            return (testDecodedTokenExp !== '') ? testDecodedTokenExp : (async () => {
                const result = await getExpClaimFromJwt(accessToken);
                if (result === undefined) throw Error('No expiration claim found in JWT');
                return result;
            })();
        };

        const jwtExpiration = Number(await getDecodedTokenExp()) - EXPIRATION_OFFSET;
        let currentTime = Math.floor(Date.now() / 1000);

        return currentTime >= jwtExpiration;
    } catch (error) {
        redirectToPage(LOGIN_URL);
    }
    return true;
};

export default jwtIsExpired;
