/* istanbul ignore file */
import axios from 'axios';
import redirectToPage from '@Routing/redirectToPage';
import getCookie from './getCookie';

const setupAPI = (oktaAccessToken, { TEST_ENV, LOGIN_URL }) => {
    
    const api = axios.create();
    const ssoToken = getCookie('sf-icp-sso') !== undefined ? getCookie('sf-icp-sso') : '';
    api.interceptors.request.use(request => {
        request.headers = {
            Accept: 'application/json;charset=UTF-8',
            Authorization: `Bearer ${oktaAccessToken}`,
            'Content-Type': 'application/json;charset=UTF-8',
            'x-sf-env': TEST_ENV,
            'X-SF-SSO-TOKEN': ssoToken,
            'x-sfcustid': getCookie('SFcustId') || 'unknownxxxx' // Maps to CLIENT_ID, 11 alpha-numerics, this is padded to pass validation.
        };
        if (request.method.toLowerCase() === 'post') {
            request.headers['x-mock-user'] = true;
            request.headers['x-sfcustid'] = 'mockuserxxx'; // Maps to CLIENT_ID, 11 alpha-numerics, this is padded to pass validation.
        }
        
        return request;
    });

    api.interceptors.response.use(
        response => response,
        error => {
            if (error?.response?.status === 401) {
                // LET'S HOPE that e.g. a bad scope/role on a JWT will result in a 403.
                // A 401 _should_ return for an expired or invalid JWT.
                // TODO: revisit; we should validate the JWT before making a call.
                redirectToPage(LOGIN_URL);
            }
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        }
    );

    return api;
};

export default setupAPI;
