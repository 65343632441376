<script setup>
import { ref, watch, inject, onMounted, nextTick } from "vue";
import useSession from "../../hooks/session/useSession";
import { API, API_URL, LOGOUT_URL } from "../../util/constants/provideKeys";
import contentBundleStrings from "../../hooks/contentBundle/useContentBundle";

const logoutUrl = inject(LOGOUT_URL) ?? "";

const { showSessionLogoutModal, refreshTokens, remainingSessionTime } = useSession();

const logoutModalRef = ref(null);

const id = "session-logout-modal";

watch(
    () => showSessionLogoutModal.value,
    showSessionLogoutModalValue => {
        if (showSessionLogoutModalValue) {
            window.oneX.Modal.showModal(logoutModalRef.value, id);
        }
    }
);

onMounted(async () => {
    await nextTick();
    window.oneX.addElement(logoutModalRef.value);
});

const onClickExtend = () => {
    refreshTokens(logoutUrl);
    window.oneX.Modal.hideSessionModal();
};

const onClickLogout = () => {
    window.location.href = logoutUrl ?? "";
};
</script>

<template>
  <div ref="logoutModalRef">
    <div
      :id="id"
      data-session-timeout="true"
      data-testid="session-logout-modal"
      class="-oneX-modal__container"
      style="display: none"
    >
      <div
        class="-oneX-modal-content"
        aria-labelledby="dialogAction"
        role="dialog"
      >
        <div
          id="dialogAction"
          class="-oneX-modal-heading"
          aria-level="2"
          role="heading"
          tabindex="-1"
        >
          Are you still there?
        </div>
        <div class="-oneX-modal-body">
          {{ contentBundleStrings["sessionExpiryPreText"] }}
          <span
            id="modal_timer"
            role="timer"
            aria-atomic="true"
            aria-live=" "
            class="-oneX-font--bold"
          >
            Your session will expire in {{ remainingSessionTime }} seconds. Would you like to extend the session?
          </span>
          {{ contentBundleStrings["sessionExpiryPostText"] }}
        </div>
        <div class="-oneX-modal-footer">
          <div class="-oneX-row">
            <div class="-oneX-modal-primary__div -oneX-col">
              <button
                class="-oneX-btn-primary"
                @click="onClickExtend"
              >
                Extend
              </button>
            </div>
            <div class="-oneX-modal-secondary__div -oneX-col">
              <button
                class="-oneX-btn-secondary"
                @click="onClickLogout"
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
        <button
          class="-oneX-close-X-button"
          aria-label="Close Session Time Out"
          @click="onClickExtend"
        >
          <span class="-oneX-icon--close" />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
