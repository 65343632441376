<script setup>
import { onMounted, onUnmounted, ref, nextTick, inject } from "vue";
import Breadcrumbs from "./components/Breadcrumbs.vue";
import useSession from "./hooks/session/useSession";
import ModalSessionLogout from "./components/modal/ModalSessionLogout.vue";
import Loader from "./components/ui/Loader.vue";
import ErrorBoundary from "./components/ErrorBoundary.vue";
import useContentBundle from "./hooks/contentBundle/useContentBundle";

const appRef = ref("");

const { removeSessionEventListeners } = useSession();

const { retrieveContentBundle } = useContentBundle();

const retrieveContentPromise = retrieveContentBundle();

onMounted(async () => {
  await retrieveContentPromise;
  await nextTick();
  window.oneX.addElement(appRef.value);
});

onUnmounted(() => {
  removeSessionEventListeners();
});
</script>

<template>
  <div ref="appRef">
    <header data-type="1x-header-auth" />

    <ErrorBoundary key="breadcrumbs">
      <Breadcrumbs />
    </ErrorBoundary>

    <div class="-oneX-container app">
      <main
        id="main-container"
        class="main-content"
      >
        <Suspense>
          <!-- Page Component -->
          <template #default>
            <RouterView />
          </template>
          <template #fallback>
            <Loader />
          </template>
        </Suspense>
      </main>

      <ErrorBoundary key="logoutModal">
        <ModalSessionLogout />
      </ErrorBoundary>
    </div>

    <footer data-type="1x-footer" />
  </div>
</template>

<style scoped>
.app {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content {
  height: 100%;
  width: 100%;
}

.loader {
  padding: 50px;
}
</style>

<style>
.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.body-text-bold {
  color: #2d2929;
  font-family: "MecherleSans";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
}

.required-indicator {
  color: #fc2929;
}

.focused {
  outline: 2px solid rgb(43, 102, 155);
}

.section {
  padding-top: 32px;
}

.sub-section {
  padding-top: 16px;
}

.content-loader {
  padding: 72px;
}

.mobile-only {
  display: none;
}

.tablet-desktop-only {
  display: none;
}

.link {
  cursor: pointer;
}

@media (max-width: 767px) {
  .mobile-only {
    display: block;
  }
}

@media (min-width: 768px) {
  .tablet-desktop-only {
    display: block;
  }
}
</style>
