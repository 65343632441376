import { createApp } from 'vue';
import Vue3Sanitize from 'vue-3-sanitize';
import router from '@Routing/router';
import App from './App.vue';
import LoadingApp from '@Components/LoadingApp.vue';
import setupConfig from '@Config/setupConfig';
import setupAPI from '@Config/setupAPI';
import getFreshOktaAccessToken from '@Config/getFreshOktaAccessToken';
import redirectToPage from '@Routing/redirectToPage';
import useSession from '@Hooks/session/useSession';
import { API } from '@Util/constants/provideKeys';
import getCookie from '@Config/getCookie';
import jwtIsExpired from '@Util/jwtIsExpired';
import constants from '@Util/constants/constants';

// Show a loading spinner before we know if the user is authenticated
const preLoadedApp = createApp(LoadingApp);
preLoadedApp.mount('#app');

// Do this asynchronously so that if running locally, we can
// query to get a user token
// This is called an IIFE if you have never seen it before
(async () => {
    const app = createApp(App);
    app.use(router);

    // TODO: config sanatize tag options
    const sanitizeConfig = {
        allowedTags: false,
        allowedAttributes: false
    };
    app.use(Vue3Sanitize, sanitizeConfig);

    // Set up all of our global props like urls
    const config = setupConfig();
    Object.entries(config).forEach(([key, value]) => {
        app.provide(key, value);
    });

    const { LOGIN_URL } = setupConfig();
    const accessToken = getCookie(constants.cookies.OKTA_ACCESS_TOKEN);
    const refreshToken = getCookie(constants.cookies.OKTA_REFRESH_TOKEN);

    if (!import.meta.env.VITE_MOCK_USER && (accessToken === undefined || refreshToken === undefined)) {
        redirectToPage(LOGIN_URL);
    }

    let token = accessToken;

    // jwtIsExpired is now async, so we need to await it to retrieve the boolean value. This await is NOT redundant.
    if (await jwtIsExpired()) {
        token = await getFreshOktaAccessToken();
    }

    if (import.meta.env.VITE_MOCK_USER) {
        // Fetching a mock user from the s3 bucket does not require Auth, like calling DASL does.
        token = 'asdf';
    }

    const { startUserSession } = useSession();

    // Set up our api object so that it can pass the sso token for each call
    const api = setupAPI(token, config);
    app.provide(API, api);

    // begin the user session and timer
    startUserSession(config);
    preLoadedApp.unmount();
    app.mount('#app');
})();
