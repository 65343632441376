import * as jose from 'jose';
import setupConfig from "@Config/setupConfig.js";

const B2C_AUDIENCE = 'sf.b2c://api';
const UNAUTHORIZED = 'Unauthorized';

export const verifyJwtSignature = async jwt => {
    try {
        const { OKTA_ISSUER_URL } = setupConfig();

        // Will throw an error if the default of NON_EXISTENT is used. This URL is necessary
        // for the remote JWK set to be created. Without it, the JWT cannot be verified.
        const JWKS = jose.createRemoteJWKSet(new URL(`${OKTA_ISSUER_URL}/v1/keys`));

        // From Jose Docs: https://github.com/panva/jose/blob/main/docs/functions/jwks_remote.createRemoteJWKSet.md#function-createremotejwkset
        const options = {
            issuer: OKTA_ISSUER_URL.toString(),
            audience: B2C_AUDIENCE
        };

        return await jose.jwtVerify(jwt, JWKS, options).catch(async error => {
            if (error?.code === 'ERR_JWKS_MULTIPLE_MATCHING_KEYS') {
                for await (const publicKey of error) {
                    try {
                        return await jose.jwtVerify(jwt, publicKey, options);
                    } catch (innerError) {
                        if (innerError?.code === 'ERR_JWS_SIGNATURE_VERIFICATION_FAILED') {
                            continue;
                        }
                        throw innerError;
                    }
                }
                throw new jose.errors.JWSSignatureVerificationFailed();
            }

            throw error;
        });
    } catch (error) {
        throw new Error(UNAUTHORIZED);
    }
};
