/* istanbul ignore file */
import { createRouter, createWebHistory } from 'vue-router';
import { IS_PRODUCTION_ENV } from '@Util/constants/provideKeys';
import { inject } from 'vue';

const isProd = inject(IS_PRODUCTION_ENV);
const DashboardPage = () => import('@Pages/Dashboard/DashboardPage.vue');
const NotFoundPage = () => import('@Pages/NotFound/NotFoundPage.vue');
const ErrorPage = () => import('@Pages/ErrorPages/ErrorPage.vue');
const NoChangesPage = () => import('@Pages/ErrorPages/NoChangesError.vue');
const CompletionPage = () => import('@Pages/Completion/CompletionPage.vue');
const MockUserPage = () => import('@Pages/MockUserSelection/MockUserPage.vue');

const routes = [
    { path: '/', name: 'home', component: DashboardPage },
    { path: '/error', name: 'error', component: ErrorPage },
    { path: '/noChanges', name: 'noChanges', component: NoChangesPage },
    { path: '/completion', name: 'completion', component: CompletionPage },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFoundPage }
];

if (!isProd) {
    routes.push({
        path: '/mock',
        name: 'mockUser',
        component: MockUserPage
    });
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

export default router;
