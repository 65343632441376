const constants = {
    times: {
        TWO_MINUTE_FIFTY_FIVE_IN_SECONDS: 175,
        TWO_MINUTE_FIFTY_SIX_IN_SECONDS: 176,
        ONE_MINUTE_AND_THIRTY_IN_SECONDS: 90,
        FIFTEEN_MINUTES_IN_SECONDS: 15 * 60,
        FIFTEEN_MINUTES_AND_THIRTY_IN_SECONDS: 15.5 * 60
    },
    cookies: {
        OKTA_ACCESS_TOKEN: 'sf-cauth1',
        OKTA_REFRESH_TOKEN: 'sf-cauth2'
    }
};

export default constants;
