/* istanbul ignore file */
import { exchangeOauth2Tokens } from 'customer-oauth2-token-management-utility';
import redirectToPage from '@Routing/redirectToPage';
import getCookie from './getCookie';
import setupConfig from '@Config/setupConfig';
import constants from '@Util/constants/constants';


const { LOGIN_URL } = setupConfig();

const getFreshOktaAccessToken = async () => {
    let oktaAccessToken;
    try {
        const accessToken = getCookie(constants.cookies.OKTA_ACCESS_TOKEN);
        const refreshToken = getCookie(constants.cookies.OKTA_REFRESH_TOKEN);

        if (accessToken !== undefined && refreshToken !== undefined) {
            const successfulRefresh = await exchangeOauth2Tokens();
            if (successfulRefresh) {
                // This will be a brand new Token, successfully refresh.
                oktaAccessToken = getCookie(constants.cookies.OKTA_ACCESS_TOKEN);
            }
        } else {
            redirectToPage(LOGIN_URL);
        }
    } catch (error) {
        redirectToPage(LOGIN_URL);
    }

    return oktaAccessToken;
};

export default getFreshOktaAccessToken;
