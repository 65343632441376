
<script>
/* istanbul ignore file */
import Loader from "./ui/Loader.vue";

export default {
  name: "LoadingApp",
  components: {
    Loader
  }
};
</script>


<script setup>
</script>

<template>
  <div class="-oneX-container">
    <Loader class="loader" />
  </div>
</template>

<style scoped>
.loader {
  padding: 50px;
}
</style>
