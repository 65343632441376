import axios from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { CONTENT_BUNDLE_FILE } from '../../util/constants/provideKeys';

const contentBundleStrings = ref({});
const isRetrievingContentBundle = ref(true);
const isContentBundleErrored = ref(false);

const useContentBundle = () => {
    const retrieveContentBundle = async () => {
        const router = useRouter();

        isContentBundleErrored.value = false;
        try {
            const responseData = await axios.get(`${CONTENT_BUNDLE_FILE}`).then(response => response.data);
            contentBundleStrings.value = responseData;
            console.log(`Static Content file ${CONTENT_BUNDLE_FILE} loaded.`);
            isRetrievingContentBundle.value = false;
        } catch (e) {
            console.error(`Unable to fetch Static Content: ${JSON.stringify(e)}`);
            isRetrievingContentBundle.value = false;
            isContentBundleErrored.value = true;
            router?.push('error');
        }
    };

    return {
        contentBundleStrings,
        retrieveContentBundle,
        isRetrievingContentBundle,
        isContentBundleErrored
    };
};

export default useContentBundle;
